import { isEmpty, isObject, pick, pickBy } from 'lodash';
import { LinkProps } from 'next/link';
import { NextRouter } from 'next/router';

import { IconType } from 'Components/1-atoms/Icon/Icon';
import { PermissionKey } from 'Helpers/permissionHelper';
import { StringKey } from 'Helpers/strings';
import { NavigationSection } from 'Sombra/api';

import { TierFlagKey } from './store/tier';

interface BaseRoute {
  key: string;
  path: string;
  text: string;
  subText?: string;
  permission?: PermissionKey;
  section?: string;
  ldFlag?: string;
  order?: number;
  queryParamsToKeep?: string[];
  query?: Record<string, string>;
  new?: boolean;
  beta?: boolean;
  tierFlag?: TierFlagKey;
  isActiveBasedOnQueryParam?: boolean;
}

// Icon required if no nav group (subroute are not showing icons)
interface RouteWithNavGroup extends BaseRoute {
  navGroup?: never;
  icon: IconType;
}
//Icon optional if inside nav group
interface RouteWithoutNavGroup extends BaseRoute {
  navGroup: RouteNavGroup;
  icon?: IconType;
}

export type Route = RouteWithNavGroup | RouteWithoutNavGroup;

interface RouteSection {
  key: string;
  text: StringKey;
  icon?: IconType;
  tierFlag?: TierFlagKey;
}

type RouteNavGroup =
  | 'shortTerm'
  | 'projects'
  | 'projectsV2'
  | 'cbs'
  | 'forms'
  | 'timesheetGroup'
  | 'reports'
  | 'employees'
  | 'resources';

export const routeSections: RouteSection[] = [
  {
    key: NavigationSection.Operations,
    text: 'sidebar_title_operations',
  },
  {
    key: NavigationSection.Management,
    text: 'sidebar_title_management',
  },
  {
    key: NavigationSection.Insight,
    text: 'sidebar_title_insight',
  },
  {
    key: NavigationSection.Configuration,
    text: 'sidebar_title_configuration',
  },
  {
    key: 'formSection',
    text: 'sidebar_title_forms',
  },
];

export const unauthenticatedRoutes = {
  signUp: {
    path: '/signup',
  },
  signUpEmailSent: {
    path: '/signup-email-sent',
  },
  signUpCallback: {
    path: '/signup-callback',
  },
  emailConfirmationRequired: {
    path: '/email-confirmation-required',
  },
  publicDailyLogs: {
    path: '/public/daily-logs',
  },
  publicWorkOrder: {
    path: '/public/work-order',
  },
  signatureSuccess: {
    path: '/public/daily-logs/signature-success',
  },
};

export const unauthenticatedPaths = (
  Object.keys(unauthenticatedRoutes) as Array<
    keyof typeof unauthenticatedRoutes
  >
).map((key) => unauthenticatedRoutes[key].path);

export const routes = {
  shortTermGroup: {
    key: 'shortTerm',
    path: '/short-term',
    text: 'sidebar_short_term',
    icon: 'planning-short-term',
    permission: 'timeline:view',
    section: NavigationSection.Operations,
    navGroup: 'shortTerm',
    queryParamsToKeep: ['date', 'search'] as string[],
    tierFlag: 'shortTerm',
  },
  shortTermCalendar: {
    key: 'shortTermCalendar',
    path: '/short-term',
    text: 'sidebar_calendar',
    icon: 'planning-short-term',
    permission: 'timeline:view',
    navGroup: 'shortTerm',
    queryParamsToKeep: ['date', 'search'] as string[],
    tierFlag: 'shortTerm',
  },
  dailyLogList: {
    key: 'dailyLogList',
    path: '/daily-logs',
    text: 'sidebar_daily_log_list',
    icon: 'navigation-menu',
    permission: 'timeline:view',
    navGroup: 'shortTerm',
    queryParamsToKeep: ['date'] as string[],
    tierFlag: 'shortTerm',
  },
  dailyLog: {
    key: 'dailyLog',
    path: '/daily-log/[dailyLogId]',
    text: 'sidebar_short_term',
    icon: 'planning-short-term',
    permission: 'dailyLog:view',
    tierFlag: 'dailyLog',
  },
  priceAnalysis: {
    key: 'priceAnalysis',
    path: '/daily-log/[dailyLogId]/price-analysis',
    text: 'sidebar_short_term',
    icon: 'planning-short-term',
    permission: 'priceAnalysis:view',
    tierFlag: 'projectCost',
  },
  projectScheduleGroup: {
    key: 'projectScheduleGroup',
    path: '/resource-planner',
    text: 'sidebar_project_schedule',
    icon: 'resource-planner',
    permission: 'projectSchedule:view',
    ldFlag: 'projectScheduleEnabled',
    section: NavigationSection.Operations,
    tierFlag: 'resourcePlanner',
  },
  projectGroup: {
    key: 'projectGroup',
    path: '/projects/list',
    text: 'sidebar_projectGroup',
    icon: 'project-blueprint-buildings',
    permission: 'projectList:view',
    section: NavigationSection.Management,
    navGroup: 'projects',
  },
  projectList: {
    key: 'projectList',
    path: '/projects/list',
    text: 'list',
    icon: 'project',
    permission: 'projectList:view',
    navGroup: 'projects',
    order: 10,
  },
  projectGrid: {
    key: 'projectGrid',
    path: '/projects/grid',
    text: 'grid',
    icon: 'project',
    permission: 'projectList:view',
    navGroup: 'projects',
    order: 20,
    tierFlag: 'projectKanban',
  },
  projectControl: {
    key: 'projectControl',
    path: '/projects/control',
    text: 'control',
    icon: 'project',
    permission: 'projectPage:view',
    navGroup: 'projects',
    order: 30,
    queryParamsToKeep: ['projectId'] as string[],
  },
  files: {
    key: 'files',
    path: '/files',
    text: 'sidebar_files',
    icon: 'folder-file',
    permission: 'files:view',
    section: NavigationSection.Management,
    tierFlag: 'document',
  },
  teams: {
    key: 'teams',
    path: '/teams',
    text: 'sidebar_teams',
    icon: 'team-meeting',
    permission: 'teams:view',
    section: NavigationSection.Management,
  },
  dashboards: {
    key: 'dashboards',
    path: '/dashboards',
    text: 'sidebar_dashboard',
    icon: 'analytics-graph-bar',
    permission: 'report:view',
    section: NavigationSection.Insight,
    tierFlag: 'looker',
  },
  reportsGroup: {
    key: 'reportsGroup',
    path: '/report-daily-log',
    text: 'sidebar_report',
    icon: 'data-file-bars-search',
    permission: 'report:view',
    section: NavigationSection.Insight,
    navGroup: 'reports',
    tierFlag: 'looker',
  },
  reportDailyLog: {
    key: 'reportDailyLog',
    path: '/report-daily-log',
    text: 'reports_daily_logs',
    icon: 'data-file-bars-search',
    permission: 'report:view',
    navGroup: 'reports',
    order: 10,
    tierFlag: 'looker',
  },
  reportProject: {
    key: 'reportProject',
    path: '/report-project',
    text: 'reports_projects',
    icon: 'data-file-bars-search',
    permission: 'report:view',
    navGroup: 'reports',
    order: 20,
    tierFlag: 'looker',
  },
  map: {
    key: 'map',
    path: '/map',
    text: 'sidebar_map',
    icon: 'maps-search',
    permission: 'map:view',
    section: NavigationSection.Insight,
    tierFlag: 'map',
  },
  forms: {
    key: 'forms',
    path: '/forms',
    text: 'sidebar_forms',
    icon: 'folder-empty',
    permission: 'form:view',
    navGroup: 'forms',
    section: NavigationSection.Configuration,
    tierFlag: 'form',
  },
  formList: {
    key: 'formList',
    path: '/forms',
    text: 'sidebar_form_list',
    icon: 'folder-add',
    permission: 'form:view',
    navGroup: 'forms',
    order: 10,
    tierFlag: 'form',
  },
  globalForm: {
    key: 'globalForm',
    path: '/global-form',
    text: 'sidebar_form',
    icon: 'folder-empty',
    permission: 'formInstance:view',
    tierFlag: 'form',
  },
  formsInstances: {
    key: 'formInstances',
    path: '/form-instances',
    text: 'sidebar_form_instances',
    icon: 'layout-list',
    permission: 'formInstance:view',
    navGroup: 'forms',
    order: 20,
    tierFlag: 'form',
  },
  organizations: {
    key: 'organizations',
    path: '/organizations',
    text: 'sidebar_organizations',
    icon: 'building',
    permission: 'organizations:manage',
    section: NavigationSection.Configuration,
  },
  organization: {
    key: 'organization',
    path: '/organizations/[organizationId]',
    text: 'sidebar_organizations',
    icon: 'building',
    permission: 'organizationPage:view',
  },
  resources: {
    key: 'resources',
    path: '/employees',
    text: 'sidebar_resources',
    icon: 'shapes',
    permission: 'employees:manage',
    section: NavigationSection.Configuration,
    navGroup: 'resources',
    new: true,
  },
  employees: {
    key: 'employees-list',
    path: '/employees',
    text: 'sidebar_employees',
    icon: 'employee',
    permission: 'employees:manage',
    navGroup: 'resources',
    order: 10,
  },
  roleDetailed: {
    key: 'roleDetailed',
    path: '/occupation/[roleId]',
    text: 'role_detailed_page_title',
    icon: 'role',
    permission: 'employees:manage',
    tierFlag: 'payroll',
  },
  inventory: {
    key: 'inventory',
    path: '/inventory',
    text: 'sidebar_inventory',
    icon: 'warehouse-cart',
    permission: 'inventory:view',
    section: NavigationSection.Configuration,
    beta: true,
    tierFlag: 'smallToolsAndSupplies',
  },
  inventoryItem: {
    key: 'inventoryItem',
    path: '/inventory/[itemId]',
    text: 'sidebar_inventory',
    icon: 'warehouse-cart',
    permission: 'inventoryItem:view',
    tierFlag: 'smallToolsAndSupplies',
  },
  equipments: {
    key: 'equipments',
    path: '/equipments',
    text: 'sidebar_equipments',
    icon: 'heavy-equipment',
    permission: 'equipments:manage',
    navGroup: 'resources',
    order: 20,
  },
  materials: {
    key: 'materials',
    path: '/materials',
    text: 'sidebar_materials',
    icon: 'material',
    permission: 'materials:manage',
    tierFlag: 'material',
    navGroup: 'resources',
    order: 30,
  },
  units: {
    key: 'units',
    path: '/units',
    text: 'sidebar_units',
    icon: 'unit',
    permission: 'customUnit:edit',
    navGroup: 'resources',
    order: 40,
  },
  cbs: {
    key: 'cbs',
    path: '/cbs',
    text: 'sidebar_task_structure',
    icon: 'task-structure',
    permission: 'activities:manage',
    section: NavigationSection.Configuration,
    navGroup: 'cbs',
    tierFlag: 'cbs',
  },
  products: {
    key: 'products',
    path: '/cbs',
    text: 'sidebar_tasks',
    icon: 'product',
    permission: 'activities:manage',
    navGroup: 'cbs',
    order: 10,
    tierFlag: 'cbs',
  },
  activities: {
    key: 'activities',
    path: '/activities',
    text: 'sidebar_subtasks',
    icon: 'activity',
    permission: 'activities:manage',
    navGroup: 'cbs',
    order: 20,
    tierFlag: 'cbs',
  },
  timesheetGroup: {
    key: 'timesheetGroup',
    path: '/timesheet',
    text: 'sidebar_timesheet',
    icon: 'time-clock-file-1',
    permission: 'timesheet:view',
    navGroup: 'timesheetGroup',
    section: NavigationSection.Insight,
    tierFlag: 'payroll',
  },
  timesheet: {
    key: 'timesheet',
    path: '/timesheet',
    text: 'sidebar_timesheet_help',
    icon: 'question-circle',
    permission: 'timesheetRule:edit',
    navGroup: 'timesheetGroup',
    order: 10,
    tierFlag: 'payroll',
  },
  timesheetRulesV2: {
    key: 'timesheetRulesV2',
    path: '/timesheet-rules-v2',
    text: 'sidebar_timesheet_rules',
    subText: 'timesheet_rules_description',
    icon: 'time-clock-file-setting',
    permission: 'timesheetRule:edit',
    navGroup: 'timesheetGroup',
    order: 20,
    tierFlag: 'payRules',
  },
  shift: {
    key: 'shift',
    path: '/shift',
    text: 'shift_page_title',
    subText: 'shift_page_description',
    icon: 'time-clock-circle',
    permission: 'shift:manage',
    navGroup: 'timesheetGroup',
    order: 30,
    tierFlag: 'payRules',
  },
  workHourTypes: {
    key: 'workHourTypes',
    path: '/work-hour-types',
    text: 'work_hour_types_page_title',
    icon: 'warehouse-cart-worker',
    permission: 'workHourType:edit',
    navGroup: 'timesheetGroup',
    order: 40,
    tierFlag: 'payroll',
  },
  employeesWorkedHours: {
    key: 'employeesWorkedHours',
    path: '/employees-worked-hours',
    text: 'sidebar_employees_worked_hours',
    subText: 'timesheet_employees_worked_hours_description',
    icon: 'employee',
    permission: 'timesheetRule:view',
    navGroup: 'timesheetGroup',
    order: 50,
    tierFlag: 'payRules',
  },
  timesheetExport: {
    key: 'timesheetExport',
    path: '/timesheet-export',
    text: 'common_export_button',
    subText: 'timesheet_export_description',
    icon: 'download',
    permission: 'timesheet:view',
    navGroup: 'timesheetGroup',
    order: 60,
    tierFlag: 'payroll',
  },
  users: {
    key: 'users',
    path: '/users',
    text: 'sidebar_users',
    icon: 'employee',
    permission: 'administration:view',
  },
  tenantSettings: {
    key: 'tenantSettings',
    path: '/tenant-settings',
    text: 'sidebar_settings',
    icon: 'cog',
    permission: 'administration:view',
  },
  holidaySettings: {
    key: 'holidaySettings',
    path: '/holiday-settings',
    text: 'sidebar_holidays',
    icon: 'calendar-plane',
    permission: 'administration:view',
    ldFlag: 'publicHolidays',
    tierFlag: 'holidays',
  },
  employeeTimeCardDailySummary: {
    key: 'employeeTimeCardDailySummary',
    path: '/employee-timecard',
    text: 'sidebar_employee_dashboard',
    icon: 'timecard',
    permission: 'timecard:view',
    section: NavigationSection.Operations,
    mobileSection: 'shortTerm',
    order: 10,
    tierFlag: 'timecard',
  },
  employeeTimeCard: {
    key: 'employeeTimeCard',
    path: '/timecard-summary',
    text: 'sidebar_timecard_summary',
    icon: 'worked-hours',
    permission: 'timecard:view',
    section: NavigationSection.Operations,
    order: 20,
    tierFlag: 'workedHours',
  },
  employeeTimeCardPunch: {
    key: 'employeeTimeCardPunch',
    path: '/employee-timecard/punch/[date]',
    text: 'sidebar_employee_dashboard',
    icon: 'timecard',
    permission: 'timecard:view',
    tierFlag: 'timecard',
  },
  logbook: {
    key: 'logbook',
    path: '/logbook',
    text: 'Logbook',
    icon: 'logbook',
    permission: 'logbook:history:view',
    ldFlag: 'logbookIntegration',
    section: NavigationSection.Operations,
    mobileSection: 'shortTerm',
    order: 30,
    new: true,
    tierFlag: 'logbook',
  },
  webhooks: {
    key: 'webhooks',
    path: '/webhooks',
    text: 'sidebar_webhooks',
    icon: 'webhook',
    ldFlag: 'webhooksEnabled',
    permission: 'webhooks:view',
    tierFlag: 'webhooks',
  },
  auditLog: {
    key: 'auditLog',
    path: '/audit-log',
    text: 'sidebar_audit_log',
    icon: 'audit-log',
    permission: 'auditLogs:view',
    tierFlag: 'auditLog',
  },
  zapier: {
    key: 'zapier',
    path: '/zapier',
    text: 'sidebar_zapier',
    icon: 'integrations',
    ldFlag: 'zapierPageEnabled',
    permission: 'administration:view',
    tierFlag: 'zapier',
  },
  integrations: {
    key: 'integrations',
    path: '/integrations',
    text: 'sidebar_integrations',
    icon: 'integrations',
    ldFlag: 'enableQuickbookIntegration',
    permission: 'integrations:view',
    tierFlag: 'integrations',
  },

  workOrders: {
    key: 'workOrders',
    path: '/work-orders',
    text: 'sidebar_workOrders',
    icon: 'work-order',
    ldFlag: 'enableWorkOrders',
    section: NavigationSection.Management,
    permission: 'workOrder:view',
    tierFlag: 'workOrders',
    new: true,
  },
} as const;

export const routeValues: Route[] = Object.values(routes);

export function isActivePath(
  router: NextRouter,
  activePath: LinkProps['href'] | string[],
): boolean {
  let currentPath = router.pathname || '';
  const { layout } = router.query;

  //temp
  if (layout && !Array.isArray(layout)) {
    currentPath = currentPath.replace(`[layout]`, layout);
  }

  let isActive = false;

  if (Array.isArray(activePath)) {
    isActive =
      activePath.find(
        (path) => currentPath === path || currentPath.startsWith(`${path}/`),
      ) != null;
  } else if (typeof activePath === 'object') {
    isActive =
      JSON.stringify(router.query) === JSON.stringify(activePath.query) &&
      router.pathname === activePath.pathname;
  } else {
    isActive =
      currentPath === activePath || currentPath.startsWith(`${activePath}/`);
  }

  return isActive;
}

export function getLinkHref(
  route: Route,
  options: { routerQuery: NextRouter['query'] },
): LinkProps['href'] {
  const href = pickBy(
    {
      pathname: route.path,
      query: {
        ...pick(options.routerQuery, route.queryParamsToKeep),
        ...route.query,
      },
    },
    (option) => !(isObject(option) && isEmpty(option)),
  );

  return href;
}
